import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastNotifyService } from './toast-notify.service';
import { ToastNotifyComponent } from './toast-notify/toast-notify.component';

@NgModule({
  declarations: [ToastNotifyComponent],
  exports: [ToastNotifyComponent],
  imports: [
    CommonModule
  ],
  providers: [ToastNotifyService]
})
export class ToastConfigModule { }
