<div class="container-fluid content">
    <div class="row">
        <div id="checkout" class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
            <div class="box">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <app-checkout-summary></app-checkout-summary>
        </div>
    </div>
</div>