<div class="acount-sidebar">
    <div class="white-box">
        <div class="side-content">
            <div class="title">
                <i class="fa fa-user text-primary" aria-hidden="true"></i> ACCOUNT SETTINGS
            </div>
            <div class="sub-title" [routerLinkActive]="['active']">
                <a [routerLink]="['/account/profile']">
                    Basic Information
                </a>
            </div>
            <div class="sub-title" [routerLinkActive]="['active']">
                <a [routerLink]="['/account/addresses']">
                    Manage Addresses
                </a>
            </div>
            <div class="sub-title" [routerLinkActive]="['active']">
                <a [routerLink]="['/account/changepassword']">
                    Change Password
                </a>
            </div>
        </div>
        <div class="side-content">
            <div class="title" [routerLinkActive]="['active']">
                <a [routerLink]="['/account/myorders']">
                    <i class="fa fa-shopping-bag text-primary" aria-hidden="true"></i>
                    MY ORDERS
                </a>
            </div>
        </div>
    </div>
</div>