import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/config/http-config/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private httpService: HttpService) { }

  createOrder(postData: Object): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/order/checkout/create';
    obj.postData = postData;
    return this.httpService.post(obj);
  }

  verifyOrder(postData: Object, header: Object): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/order/checkout/verify';
    obj.postData = postData;
    obj.header = header;
    return this.httpService.post(obj);
  }

  cancelTransaction(postData: Object, id: string): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/order/checkout/cancel/' + id;
    obj.postData = postData;
    return this.httpService.put(obj);
  }

  getOrderReview(oid: string): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/order/review/' + oid;
    return this.httpService.get(obj);
  }

  getOrderReviewSummary(oid: string): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/order/review/summary/' + oid;
    return this.httpService.get(obj);
  }


  // Cashfree API
  payNow(postData: Object): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/order/checkout/pay';
    obj.postData = postData;
    return this.httpService.post(obj);
  }

  payComplete(postData: Object): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/order/checkout/pay/complete';
    obj.postData = postData;
    return this.httpService.post(obj);
  }

  payStatus(): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/order/checkout/pay/status';
    return this.httpService.get(obj);
  }

}
