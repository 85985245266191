export const environment = {
  production: true,
  appSettings: {
    isTestMode: false,
    port: 4580,
    hostUrl: "https://paithaniandsilksaree.com",
    baseURI: 'https://api.paithaniandsilksaree.com',
    razorkey: 'rzp_test_E8qozVyRZ4dsng',
    aes_salt: 'ddfbccae-b4c4-11',
    pageSize: 10,
    google_id: "531422928609-oco2mh3nlurc67kl0e20akinetqdlc86.apps.googleusercontent.com",
    gpay_merchant: "BCR2DN4T5DJKJUYP",
    cashfree: "175521f2c207cdbcbfbb94049d125571",
  },
};
