import { Component, OnInit, OnDestroy } from '@angular/core';
import { JsonLDService, BreadCrumbListSchema, WebpageSchema } from 'src/app/config/app-config/schemaorg.service';
import { SeoService } from 'src/app/config/app-config/seo.service';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit, OnDestroy {

  constructor(
    private seoService: SeoService,
    private jsonService: JsonLDService,
  ) { }

  ngOnInit(): void {
    this.seoService.generateTags({
      title: 'Shipping Policy - Paithani & Silk Sarees',
      description: `Shipping and delivery policy by paithani and silk sarees.`,
      robot: 'index, follow',
      slug: '/shipping'
    });
    this.jsonService.makeStructuredSchema(BreadCrumbListSchema(window.location.href, [{
      "routes": "",
      "name": "Home - Paithani & Silk Sarees",
    },
    {
      "routes": "/shipping",
      "name": "Shipping Policy - Paithani & Silk Sarees",
    }]));
    this.jsonService.makeStructuredSchema(WebpageSchema(window.location.href, 'Shipping Policy - Paithani & Silk Sarees'));
  }

  ngOnDestroy(): void {
    this.jsonService.removeStructuredData();
  }

}
