import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessGuard } from './config/app-config/access.guard';
import { PageNotFoundComponent } from './core-parts/page-not-found/page-not-found.component';
import { SessionAppLayoutComponent } from './layout/session-app-layout/session-app-layout.component';
import { UserAccountLayoutComponent } from './layout/user-account-layout/user-account-layout.component';
import { UserAppLayoutComponent } from './layout/user-app-layout/user-app-layout.component';
import { AboutUsComponent } from './pages/about/about-us/about-us.component';
import { ContactUsComponent } from './pages/about/contact-us/contact-us.component';
import { FaqComponent } from './pages/about/faq/faq.component';
import { SitemapComponent } from './pages/about/sitemap/sitemap.component';
import { CheckoutComponent } from './pages/checkout/checkout/checkout.component';
import { PrivacyComponent } from './pages/policy/privacy/privacy.component';
import { RefundCancellationComponent } from './pages/policy/refund-cancellation/refund-cancellation.component';
import { ShippingComponent } from './pages/policy/shipping/shipping.component';
import { TermsUseComponent } from './pages/policy/terms-use/terms-use.component';

const routes: Routes = [
  { path: '404', component: PageNotFoundComponent },
  {
    path: '',
    component: UserAppLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/home/home.module').then(m => m.HomeModule),
        data: {
          title: "Buy Best Paithani & Silk Sarees"
        }
      },

      {
        path: 'privacy',
        component: PrivacyComponent,
        data: {
          title: "Privacy | Paithani & Silk Sarees"
        }
      },
      {
        path: 'terms',
        component: TermsUseComponent,
        data: {
          title: "Terms | Paithani & Silk Sarees"
        }
      },
      {
        path: 'refundandcancellation',
        component: RefundCancellationComponent,
        data: {
          title: "Refund & Cancellation | Paithani & Silk Sarees"
        }
      },
      {
        path: 'about',
        component: AboutUsComponent,
        data: {
          title: "About | Paithani & Silk Sarees"
        }
      },
      {
        path: 'contact',
        component: ContactUsComponent,
        data: {
          title: "Contact Us | Paithani & Silk Sarees"
        }
      },
      {
        path: 'faq',
        component: FaqComponent,
        data: {
          title: "FAQ | Paithani & Silk Sarees"
        }
      },
      {
        path: 'shipping',
        component: ShippingComponent,
        data: {
          title: "Shipping Policy | Paithani & Silk Sarees"
        }
      },
      {
        path: 'account',
        component: UserAccountLayoutComponent,
        loadChildren: () => import('src/app/pages/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'collections',
        loadChildren: () => import('src/app/pages/collections/collections.module').then(m => m.CollectionsModule),
        data: {
          title: "Buy Best Paithani & Silk Saree Collections In India"
        },
      },
      {
        path: 'cart',
        canActivate: [AccessGuard],
        loadChildren: () => import('src/app/pages/cart/cart.module').then(m => m.CartModule),
        data: {
          title: "Cart | Paithani & Silk Sarees"
        },
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [AccessGuard],
        loadChildren: () => import('src/app/pages/checkout/checkout.module').then(m => m.CheckoutModule),
        data: {
          title: "Checkout | Paithani & Silk Sarees"
        },
      },
      {
        path: ':product_slug',
        data: {
          title: "Best Paithani & Silk Sarees"
        },
        loadChildren: () => import('src/app/pages/product-details/product-details.module').then(m => m.ProductDetailsModule)
      }
    ]
  },
  {
    path: 'session',
    component: SessionAppLayoutComponent,
    loadChildren: () => import('src/app/pages/session/session.module').then(m => m.SessionModule),
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', initialNavigation: 'enabledBlocking', paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
