import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modals: any[] = [];

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string) {
    const modal = this.modals.find(x => x.id === id);
    if (modal) modal.open();
    else throwError(`Modal with this ${id} id is not present.`);
  }

  close(id: string) {
    const modal = this.modals.find(x => x.id === id);
    if (modal) modal.close();
    else throwError(`Modal with this ${id} id is not present.`);
  }

  closeAll(): void {
    if (this.modals.length > 0) {
      this.modals.map(modal => modal.close());
    }
  }
}
