import { Injectable } from '@angular/core';
// import * as bootstrap from 'bootstrap';

@Injectable({
  providedIn: 'root'
})
export class AppSharedService {

  constructor() {
    this.fillAuthData();
  }

  fillAuthData() {
    let authData: any = JSON.parse(localStorage.getItem('PAITHANISESSIONSTORE') as any);
    if (authData) {
      this.authData = authData;
      this.token = authData.token;
      this.fullName = authData.fullname;
      this.isAuth = true;
    } else {
      this.authData = null;
      this.token = '';
      this.fullName = '';
      this.isAuth = false;
    }
  }

  static authData: any[] = [];
  get authData(): any { return AppSharedService.authData; }
  set authData(val: any) { AppSharedService.authData = val; }

  static isAuth: boolean = false;
  get isAuth(): boolean { return AppSharedService.isAuth; }
  set isAuth(val: boolean) { AppSharedService.isAuth = val; }

  static token: string = '';
  get token(): string { return AppSharedService.token; }
  set token(val: string) { AppSharedService.token = val; }

  static fullName: string = '';
  get fullName(): string { return AppSharedService.fullName; }
  set fullName(val: string) { AppSharedService.fullName = val; }

  static cartCount: number = 0;
  get cartCount(): number { return AppSharedService.cartCount; }
  set cartCount(val: number) { AppSharedService.cartCount = val; }

  static activateTemplate: string = '';
  get activateTemplate(): string { return AppSharedService.activateTemplate; }
  set activateTemplate(val: string) { AppSharedService.activateTemplate = val; }
}
