import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AppSharedService } from 'src/app/config/app-config/app-shared.service';

@Component({
  selector: 'app-user-account-layout',
  templateUrl: './user-account-layout.component.html',
  styleUrls: ['./user-account-layout.component.scss']
})
export class UserAccountLayoutComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: any,
    public shared: AppSharedService
  ) {
    this.document.body.style.background = 'rgba(243, 243, 243, 0.933)';
  }

  ngOnDestroy(): void {
    this.document.body.style.background = '#FFF';
  }

  ngOnInit(): void {
  }

}
