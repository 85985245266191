import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastNotifyService } from '../toast-config/toast-notify.service';
import { AppSharedService } from '../app-config/app-shared.service';

@Injectable()
export class HttpService {
    private pendingHTTPRequests$ = new Subject<void>();
    constructor(
        private _http: HttpClient,
        private _toastService: ToastNotifyService,
        private _sharedService: AppSharedService
    ) {
    }

    // Cancel Pending HTTP calls
    public cancelPendingRequests() {
        this.pendingHTTPRequests$.next();
    }

    public onCancelPendingRequests() {
        return this.pendingHTTPRequests$.asObservable();
    }


    private extractData(res: HttpResponse<Object>) {
        let response = res.body;
        return response;
    }

    _error(error: HttpErrorResponse): any {
        if (error.status === 401) this._sharedService.isAuth = false;
        this._toastService.handleErrors(error);
        return throwError(error.error);
    }

    //---------------------------------------------------------GET---------------------------------------------

    get(obj: any): Observable<any> {
        const params = obj.params;
        const url = obj.url;

        const headers: HttpHeaders = new HttpHeaders();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');

        return this._http.get(url,
            { headers: headers, observe: 'response', params, reportProgress: false, responseType: 'json', withCredentials: false }
        ).pipe(
            map(this.extractData),
            catchError(e => this._error(e))
        );
    }

    downloadFileWithProgress(obj: any): Observable<any> {
        const params = obj.params;
        const url = obj.url;
        const headers: HttpHeaders = new HttpHeaders();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.get(url, { headers: headers, observe: 'events', params, reportProgress: true, responseType: 'blob', withCredentials: false })
            .pipe(
                map(response => this.getEventMessage(response)),
                catchError(e => this._error(e))
            );
    }

    downloadFile(obj: any, filename?: any): Observable<any> {
        const params = obj.params;
        const url = obj.url;
        const headers: HttpHeaders = new HttpHeaders();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.get(url, { headers: headers, observe: 'response', params, reportProgress: false, responseType: 'blob', withCredentials: false })
            .pipe(
                map(
                    response => {
                        const blob = new Blob([response.body as Blob], { type: response.headers.get("Content-Type") as any });
                        this.download(blob, filename);
                    }
                ),
                catchError(e => this._error(e))
            );
    }

    private download(data: Blob, filename: string) {
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename;
        link.click();
    }

    // ------------------------------ Upload File ----------------------------------------

    uploadFile(obj: any): Observable<any> {
        const params = obj.params;
        const postData = obj.postData;
        const url = obj.url;
        const headers: HttpHeaders = new HttpHeaders();
        let csrf = '';
        headers.set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(url, postData, { headers: headers, observe: 'events', params, reportProgress: true, responseType: 'json' })
            .pipe(
                map(response => this.getEventMessage(response)),
                catchError(e => this._error(e))
            );
    }

    private getEventMessage(event: HttpEvent<any>) {
        switch (event.type) {
            case HttpEventType.UploadProgress:
                return this.fileUploadProgress(event);
            case HttpEventType.DownloadProgress:
                return this.fileDownloadProgress(event);
            case HttpEventType.Response:
                return this.apiResponse(event);
            default:
                return;
        }
    }

    private fileDownloadProgress(event: any) {
        const percent = Math.round(100 * event.loaded / event.total);
        return { status: 'progress', precentage: percent, totalSize: this.formatBytes(event.total), completeSize: this.formatBytes(event.loaded) };
    }

    private fileUploadProgress(event: any) {
        const percent = Math.round(100 * event.loaded / event.total);
        return { status: 'progress', precentage: percent, totalSize: this.formatBytes(event.total), completeSize: this.formatBytes(event.loaded) };
    }

    formatBytes(bytes: number, decimals = 1) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    private apiResponse(event: any) {
        return event.body;
    }

    // ---------------------------------------------------------POST---------------------------------------------

    post(obj: any): Observable<any> {
        const params = obj.params;
        const postData = obj.postData;
        const url = obj.url;
        const header: any = obj.header;
        let headers: HttpHeaders = new HttpHeaders();
        if (header && Object.keys(header).length > 0) {
            for (var prop in header) {
                headers = headers.set(prop, header[prop]);
            }
        }

        return this._http.post(url, postData, { headers: headers, observe: 'response', params, responseType: 'json' })
            .pipe(
                map(response => { return this._post(response as HttpResponse<Object>, obj); }),
                catchError(e => this._error(e))
            );
    }

    _post(res: HttpResponse<Object>, obj: any) {
        const statuscode = res.status;
        const response = res.body as any;
        switch (statuscode) {
            case 201:
                this._toastService.show('success', 'Success', response.message);
                return response;
            case 204:
                return true;
            default:
                this._toastService.show('success', 'Success', response.message);
                return response;
        }
    }

    //---------------------------------------------------------PUT---------------------------------------------

    put(obj: any): Observable<any> {
        const params = obj.params;
        const postData = obj.postData;
        const url = obj.url;
        const successToast = obj.successToast;
        const errorToast = obj.errorToast;

        const headers: HttpHeaders = new HttpHeaders();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');

        return this._http.put(url, postData, { headers: headers, observe: 'response', params, responseType: 'json' })
            .pipe(
                map(response => { return this._put(response as HttpResponse<Object>, obj); }),
                catchError(e => this._error(e))
            );
    }

    patch(obj: any): Observable<any> {
        const params = obj.params;
        const postData = obj.postData;
        const url = obj.url;
        const successToast = obj.successToast;
        const errorToast = obj.errorToast;

        const headers: HttpHeaders = new HttpHeaders();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');

        return this._http.patch(url, postData, { headers: headers, observe: 'response', params, responseType: 'json' })
            .pipe(
                map(response => { return this._put(response as HttpResponse<Object>, obj); }),
                catchError(e => this._error(e))
            );
    }

    _put(res: HttpResponse<Object>, obj: any) {
        const statuscode = res.status;
        const response = res.body as any;
        let message = '';
        if (typeof response === 'object') {
            message = response.message ? response.message : 'Unknown response comming.';
        } else {
            message = Array.isArray(response) ? '' : response;
        }
        switch (statuscode) {
            case 202:
                this._toastService.show('success', 'Success', message);
                return response;
            case 201:
                this._toastService.show('success', 'Success', message);
                return response;
            case 200:
                this._toastService.show('success', 'Success', message);
                return response;
            case 204:
                return true;
        }
        return 0;
    }


    //---------------------------------------------------------DELETE---------------------------------------------

    delete(obj: any): Observable<any> {
        const url = obj.url;
        const successToast = obj.successToast;
        const errorToast = obj.errorToast;

        const headers: HttpHeaders = new HttpHeaders();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');

        return this._http.delete(url, { headers: headers, observe: 'response', responseType: 'json' })
            .pipe(
                map(response => { return this._delete(response as HttpResponse<Object>, obj); }),
                catchError(e => this._error(e))
            );
    }

    _delete(res: HttpResponse<Object>, obj: any): any {
        let response = res.body as any;
        let message = '';
        if (typeof response === 'object') {
            message = response.message ? response.message : 'Unknown response comming.';
        } else {
            message = Array.isArray(response) ? '' : response;
        }
        this._toastService.show('success', 'Deleted', message);
        return response;
    }


    //---------------------------------------------------------POST SEARCH---------------------------------------------

    postSearch(obj: any): Observable<any> {
        let postData = obj.postData;
        const url = obj.url;

        const headers: HttpHeaders = new HttpHeaders();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');

        return this._http.post(url, { headers: headers, observe: 'response', postData, responseType: 'json' })
            .pipe(
                map(this.extractData as any),
                catchError(e => this._error(e))
            );

    }

    //---------------------------------------------------------MULTIPART---------------------------------------------

    postMultipart(obj: any): Observable<any> {
        const postData = obj.postData;
        const url = obj.url;

        const headers: HttpHeaders = new HttpHeaders();
        headers.delete('Content-Type');
        headers.set('Content-Type', 'multipart/form-data');

        return this._http.post(url, postData, { headers: headers, observe: 'response', responseType: 'json' })
            .pipe(
                map(response => { return this._multipart(response as HttpResponse<Object>, obj); }),
                catchError(e => this._error(e))
            );
    }

    putMultipart(obj: any): Observable<any> {
        const postData = obj.postData;
        const url = obj.url;

        const headers: HttpHeaders = new HttpHeaders();
        headers.delete('Content-Type');
        headers.set('Content-Type', 'multipart/form-data');

        return this._http.put(url, postData, { headers: headers, observe: 'response', responseType: 'json' })
            .pipe(
                map(response => { return this._multipart(response as HttpResponse<Object>, obj); }),
                catchError(e => this._error(e))
            );
    }

    _multipart(res: HttpResponse<Object>, obj: any): any {
        let response = res.body as any;
        switch (response.statuscode) {
            case "1":
                this._toastService.show('success', 'Success', response.message);
                return response.data;

            case "3":
                this._toastService.show('error', 'Success', response.message);
                return 0;
        }
    }


    //---------------------------------------------------------SIGN-IN / SIGN-UP---------------------------------------------

    signIn(obj: any): Observable<any> {
        const postData = obj.postData;
        const url = obj.url;

        return this._http.post(url, postData, { observe: 'response', responseType: 'json' })
            .pipe(
                map(response => { return this._signIn(response as HttpResponse<Object>, obj); }),
                catchError(e => this._error(e))
            );
    }

    _signIn(res: HttpResponse<Object>, obj: any) {
        const statuscode = res.status;
        const response = res.body as any;
        this._toastService.show('success', 'Success', response.message);
        localStorage.setItem('PAITHANISESSIONSTORE', JSON.stringify(response.data));
        this._sharedService.fillAuthData();
    }

}