import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadCrumbListSchema, JsonLDService, WebpageSchema } from 'src/app/config/app-config/schemaorg.service';
import { SeoService } from 'src/app/config/app-config/seo.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit, OnDestroy {

  constructor(
    private jsonService: JsonLDService,
    private seoService: SeoService,
  ) { }

  ngOnInit(): void {
    this.seoService.generateTags({
      title: 'About Us - Paithani & Silk Sarees',
      description: `We are Paithani & Silk Saree founded in January of 2022 and based in Yeola, Nashik, Maharashtra, India. Welcome to Paithani & Silk Saree is marketplace for people to discover the Best Paithanies, Unique Paithani Accessories and much more.`,
      robot: 'index, follow',
      slug: '/about'
    });
    this.jsonService.makeStructuredSchema(BreadCrumbListSchema(window.location.href, [{
      "routes": "",
      "name": "Home - Paithani & Silk Sarees",
    },
    {
      "routes": "/about",
      "name": "About - Paithani & Silk Sarees",
    }]));
    this.jsonService.makeStructuredSchema(WebpageSchema(window.location.href, 'About - Paithani & Silk Sarees'));
  }

  ngOnDestroy(): void {
    this.jsonService.removeStructuredData();
  }

}
