<div class="user-account">
    <div class="row">
        <div class="col-xl-3 col-lg-4 col-12 desktop-layout">
            <div class="user-account-sidebar">
                <div class="white-box">
                    <div class="account-info">
                        <img class="account-image" height="50px" width="50px" alt="profile"
                            src="//img1a.flixcart.com/www/linchpin/fk-cp-zion/img/profile-pic-male_2fd3e8.svg">
                        <div class="account-name">
                            <div class="hi">Hi,</div>
                            <div class="fullname">{{shared.fullName}}</div>
                        </div>
                    </div>
                </div>
                <app-account-sidebar></app-account-sidebar>
            </div>
        </div>
        <div class="col-xl-9 col-lg-8 col-12">
            <div class="account-content">
                <div class="white-box mobile-layout">
                    <div class="account-info">
                        <img class="account-image" height="50px" width="50px" alt="profile"
                            src="//img1a.flixcart.com/www/linchpin/fk-cp-zion/img/profile-pic-male_2fd3e8.svg">
                        <div class="account-name">
                            <div class="hi">Hi,</div>
                            <div class="fullname">{{shared.fullName}}</div>
                        </div>
                    </div>
                </div>
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-12 mobile-layout">
            <div class="user-account-sidebar">
                <app-account-sidebar></app-account-sidebar>
            </div>
        </div>
    </div>
</div>