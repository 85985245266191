import { Component, OnInit } from '@angular/core';
import { ToastNotifyService } from '../toast-notify.service';
declare var $: any;

@Component({
  selector: 'app-toast-notify',
  templateUrl: './toast-notify.component.html',
  styleUrls: ['./toast-notify.component.scss']
})
export class ToastNotifyComponent implements OnInit {
  toasts: any[] = [];
  snackbar: Snackbar = new Snackbar();
  constructor(private toaster: ToastNotifyService) { }

  ngOnInit() {
    this.toaster.toast$
      .subscribe((toast: any) => {
        // const find = this.toasts.find(x => x.type === toast.type && x.title === toast.title && x.body === toast.body);
        // if (!find) this.toasts = [toast, ...this.toasts];
        // setTimeout(() => this.toasts.pop(), toast.delay || 5000);
        if (toast) {
          if (toast.body != this.snackbar.message) {
            this.snackbar = new Snackbar();
            this.snackbar.message = toast.body;
            this.snackbar.type = toast.type;
            setTimeout(() => this.snackbar = new Snackbar(), 5000);
          }
        }
      });
  }

  remove(index: number) {
    this.toasts = this.toasts.filter((v, i) => i !== index);
  }

  closeSnackbar(): void {
    this.snackbar = new Snackbar();
  }

}

class Snackbar {
  type: string = '';
  message: string = '';
}