export class CartProducts {
    _id: string = '';
    seller: string = '';
    title: string = '';
    type: string = '';
    image: string = '';
    quantity: number = 0;
    stock: number = 0;
    price: ProductPrice = new ProductPrice();
}

class ProductPrice {
    original: number = 0;
    sell: number = 0;
}

export class CartCalc {
    item_cnt: number = 0;
    item_price: number = 0;
    original_price: number = 0;
    delivary_charges: number = 0;
    saving_price: number = 0;
    discount: number = 0;
    total_amount: number = 0;
    can_checkout: boolean = false;
}