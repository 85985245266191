import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpServiceModule } from './config/http-config/http.module';
import { HttpService } from './config/http-config/http.service';
import { ToastConfigModule } from './config/toast-config/toast-config.module';
import { NavbarComponent } from './core-parts/navbar/navbar.component';
import { NavbarService } from './core-parts/navbar/navbar.service';
import { UserAppLayoutComponent } from './layout/user-app-layout/user-app-layout.component';
import { SessionAppLayoutComponent } from './layout/session-app-layout/session-app-layout.component';
import { AppSharedService } from './config/app-config/app-shared.service';
import { AccessGuard } from './config/app-config/access.guard';
import { TokenInterceptor } from './config/app-config/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { WindowRefService } from './config/app-config/window-ref.service';
import { UserAccountLayoutComponent } from './layout/user-account-layout/user-account-layout.component';
import { AccountSidebarComponent } from './core-parts/account-sidebar/account-sidebar.component';
import { CartService } from './pages/cart/shared/cart.service';
import { FooterComponent } from './core-parts/footer/footer.component';
import { AboutUsComponent } from './pages/about/about-us/about-us.component';
import { ContactUsComponent } from './pages/about/contact-us/contact-us.component';
import { FaqComponent } from './pages/about/faq/faq.component';
import { SitemapComponent } from './pages/about/sitemap/sitemap.component';
import { PrivacyComponent } from './pages/policy/privacy/privacy.component';
import { TermsUseComponent } from './pages/policy/terms-use/terms-use.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageNotFoundComponent } from './core-parts/page-not-found/page-not-found.component';
import { RefundCancellationComponent } from './pages/policy/refund-cancellation/refund-cancellation.component';
import { ShippingComponent } from './pages/policy/shipping/shipping.component';
import { NgxHttpLoaderModule } from 'ngx-http-loader';
import { RouteChangeService } from './config/app-config/app-routing.service';
import { SeoService } from './config/app-config/seo.service';
import { JsonLDService } from './config/app-config/schemaorg.service';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    UserAppLayoutComponent,
    SessionAppLayoutComponent,
    UserAccountLayoutComponent,
    AccountSidebarComponent,
    FooterComponent,
    TermsUseComponent,
    PrivacyComponent,
    AboutUsComponent,
    ContactUsComponent,
    FaqComponent,
    SitemapComponent,
    PageNotFoundComponent,
    RefundCancellationComponent,
    ShippingComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpServiceModule,
    ToastConfigModule,
    FormsModule,
    ReactiveFormsModule,
    NgxHttpLoaderModule.forRoot(),
  ],
  providers: [
    provideClientHydration(),
    HttpService,
    NavbarService,
    AppSharedService,
    AccessGuard,
    WindowRefService,
    CartService,
    RouteChangeService,
    SeoService,
    JsonLDService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private shared: AppSharedService,
    private routerChange: RouteChangeService,
  ) { }
}
