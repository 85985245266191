export const paithani_menu_data = [
    {
        name: 'Original Paithani',
        slug: 'collections/sarees/originalpaithani',
        child: [
            {
                name: 'Bangadi Mor',
                slug: 'bangadi-mor',
            },
            {
                name: 'Munia Brocade',
                slug: 'munia-brocade',
            },
            {
                name: 'Lotus Brocade',
                slug: 'lotus-brocade',
            },
            {
                name: 'Tissue Padar',
                slug: 'tissue-padar',
            },
            {
                name: 'Butti Paithani',
                slug: 'butti',
            },
        ]
    },
    {
        name: 'Semi Pathani',
        slug: 'collections/sarees/semipaithani',
        child: [
            {
                name: 'Semi Silk',
                slug: 'bangadi-mor',
            },
            {
                name: 'Kadiyal',
                slug: 'munia-brocade',
            },
            {
                name: 'Kanjivaram',
                slug: 'lotus-brocade',
            },
            {
                name: 'Banarasi',
                slug: 'tissue-border',
            },
        ]
    }
]


export const saree_menu_data = [
    {
        name: 'Cotton Saree',
        slug: 'cotton-saree',
        child: [
            {
                name: 'Kovai Cora Cotton',
                slug: 'kovai-cora-cotton'
            },
            {
                name: 'Khadi Cotton',
                slug: 'khadi-cotton'
            }
        ]
    }
]

export const accessory_menu_data = [
    {
        name: 'Bags & Purses',
        slug: 'bags-purses'
    },
    {
        name: 'Mask',
        slug: 'mask'
    },
];