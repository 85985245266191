<!-- <div class="white-box">
    <div class="cart-header d-flex">
        <h4 class="text-muted">Order Summery <span class="badge badge-primary badge-pill">2</span>
        </h4>
    </div>
    <div class="cart-body">
        <div class="cart-item d-flex">
            <a>
                <div class="image-box">
                    <img class="item-image" alt="" src="../../../../assets/images/2.jpg">
                </div>
            </a>
            <div class="item-detail">
                <div class="">
                    <a>Yellow Paithani Silk Saree In Yeola</a>
                </div>

                <div class="itemtype">
                    Category: Paithani Original Paithani
                </div>
                <div class="seller">Seller: Kapse Paithani</div>
                <span class="sell-price">₹8999</span>
                <span class="retail-price">₹20000</span>
                <span class="discount">
                    70% Off</span>
            </div>
        </div>
    </div>
    <div class="cart-body">
        <div class="no-item-in-cart">
            <h3> No items added in cart </h3>
        </div>
    </div>
</div> -->
<!-- <div class="box">
    <div class="cart-header d-flex">
        <h4 class="text-muted">Order summary</h4>
    </div>
    <div class="cart-body">
        <div class="item-charges d-flex">
            <span class="itemsno"> Price ({{model.item_cnt}} Item)</span>
            <span class="price">₹{{model.item_price}}</span>
        </div>
        <div class="item-charges d-flex">
            <span class="itemsno"> Delivery Charges</span>
            <span class="price" *ngIf="model.delivary_charges > 0">+ ₹{{model.delivary_charges}}</span>
            <span class="discount" *ngIf="model.delivary_charges == 0">FREE</span>
        </div>
    </div>
    <div class="cart-body">
        <div class="item-total d-flex">
            <span class="itemsno"> Total Amount</span>
            <span class="price">₹{{model.total_amount}}</span>
        </div>
    </div>
    <div class="cart-body">
        <span class="discount"> Total saving amount is ₹{{model.saving_price}} on this order</span>
    </div>
</div> -->

<div id="order-summary" class="card">
    <div class="card-header">
        <h3 class="mt-4 mb-4">Order summary</h3>
    </div>
    <div class="card-body">
        <p class="text-muted">Shipping and additional costs are calculated based on the values you have entered.</p>
        <div class="table-responsive">
            <table class="table">
                <tbody>
                    <tr>
                        <td>Subtotal ({{model.item_cnt}} Item)</td>
                        <th>₹{{model.item_price}}</th>
                    </tr>
                    <tr>
                        <td>Shipping and handling</td>
                        <th *ngIf="model.delivary_charges > 0">₹{{model.delivary_charges}}</th>
                        <th *ngIf="model.delivary_charges == 0">FREE</th>
                    </tr>
                    <tr>
                        <td>Tax</td>
                        <th>₹0.00</th>
                    </tr>
                    <tr class="total" style="border: none !important; padding-top: 10px;">
                        <td style="border: none !important;">Total</td>
                        <th style="border: none !important;">₹{{model.total_amount}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
        <span *ngIf="model.saving_price" class="discount"> Total saving amount is ₹{{model.saving_price}} on this
            order.</span>

    </div>

</div>