import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/config/http-config/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private httpService: HttpService) { }

  getHomeData(params?: any): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/home';
    obj.params = params;
    return this.httpService.get(obj);
  }


  sendMessage(data: Object): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/contact/message/send';
    obj.postData = data;
    return this.httpService.post(obj);
  }
}
