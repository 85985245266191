import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowRefService {

  constructor(@Inject(PLATFORM_ID) private platformId: object) { }

  get nativeWindow(): any {
    // if (isPlatformBrowser(this.platformId)) {
    //   return _window();
    // }
    return _window();
  }
}

function _window(): any {
  return window;
}

