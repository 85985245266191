import { Component, OnInit, OnDestroy } from '@angular/core';
import { JsonLDService, BreadCrumbListSchema, WebpageSchema } from 'src/app/config/app-config/schemaorg.service';
import { SeoService } from 'src/app/config/app-config/seo.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit, OnDestroy {

  constructor(
    private seoService: SeoService,
    private jsonService: JsonLDService,
  ) { }

  ngOnInit(): void {
    this.seoService.generateTags({
      title: 'Privacy Policy - Paithani & Silk Sarees',
      description: `Privacy policy by paithani and silk sarees.`,
      robot: 'index, follow',
      slug: '/privacy'
    });
    this.jsonService.makeStructuredSchema(BreadCrumbListSchema(window.location.href, [{
      "routes": "",
      "name": "Home - Paithani & Silk Sarees",
    },
    {
      "routes": "/privacy",
      "name": "Privacy Policy - Paithani & Silk Sarees",
    }]));
    this.jsonService.makeStructuredSchema(WebpageSchema(window.location.href, 'Privacy Policy - Paithani & Silk Sarees'));
  }

  ngOnDestroy(): void {
    this.jsonService.removeStructuredData();
  }

}
