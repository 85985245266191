<div id="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <h4 class="mb-3">ABOUT</h4>
                <ul class="list-unstyled">
                    <li><a routerLink="/about">About us</a></li>
                    <li><a routerLink="/contact">Contact us</a></li>
                    <li><a routerLink="/faq">FAQ</a></li>
                    <li><a href="/sitemap.xml">Sitemap</a></li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-6">
                <h4 class="mb-3">POLICY</h4>
                <ul class="list-unstyled">
                    <li><a routerLink="/shipping">Shipping</a></li>
                    <li><a routerLink="/privacy">Privacy</a></li>
                    <li><a routerLink="/terms">Terms & Use</a></li>
                    <li><a routerLink="/refundandcancellation">Refund & Cancellation</a></li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-6">
                <h4 class="mb-3">MAIL US</h4>
                <p>Pahad Lane, Near Post Office,<br>Tal-Yeola, Dist.-Nashik, <br><strong>MH, India (423401)</strong>
                </p>
                <a routerLink="/contact">Go to contact page</a>
                <hr class="d-block d-md-none">
            </div>
            <div class="col-lg-3 col-md-6">
                <!-- <h4 class="mb-3">Get the news</h4>
                <p class="text-muted">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
                    turpis
                    egestas.</p>
                <form>
                    <div class="input-group">
                        <input type="text" class="form-control"><span class="input-group-append">
                            <button type="button" class="btn btn-outline-secondary">Subscribe!</button></span>
                    </div>
                </form>
                <hr> -->
                <h4 class="mb-3">Stay in touch</h4>
                <p class="social">
                    <a href="https://www.facebook.com/paithaniandsilksaree/" target="_blank"
                        class="facebook external"><i class="fa fa-facebook"></i></a>
                    <a href="https://twitter.com/paithani_store" target="_blank" class="twitter external"><i
                            class="fa fa-twitter"></i></a>
                    <a href="https://www.instagram.com/paithaniandsilksarees/" target="_blank"
                        class="instagram external"><i class="fa fa-instagram"></i></a>
                    <a href="mailto:paithaniandsilksaree@gmail.com" target="_blank" class="email external"><i
                            class="fa fa-envelope"></i></a>
                </p>
            </div>
        </div>
    </div>
</div>

<div id="copyright">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-lg-6 mb-2 mb-lg-0">
                <p class="text-center text-lg-left">©2019 Paithani & Silk Sarees.</p>
            </div>
        </div>
    </div>
</div>