import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppSharedService } from 'src/app/config/app-config/app-shared.service';
import { HttpService } from 'src/app/config/http-config/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  invokeCartCount = new Subject<boolean>();

  constructor(
    private httpService: HttpService,
    private sharedService: AppSharedService
  ) {
    this.invokeCartCount.subscribe((res) => {
      if (res === true) this.cartCount();
    });
  }

  addToCart(postData: Object): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/cart/add';
    obj.postData = postData;
    return this.httpService.post(obj);
  }

  cartTotal(): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/cart/total';
    return this.httpService.get(obj);
  }

  cartDetails(): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/cart/details';
    return this.httpService.get(obj);
  }

  cartListItems(): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/cart/itemlist';
    return this.httpService.get(obj);
  }

  updateCartItem(postData: Object): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/cart/update';
    obj.postData = postData;
    return this.httpService.put(obj);
  }

  deleteCartItem(id: string): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/cart/delete/' + id;
    return this.httpService.delete(obj);
  }

  async cartCount(): Promise<void> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/cart/count';
    let data = await this.httpService.get(obj).toPromise();
    if (data) {
      this.sharedService.cartCount = data.cart;
    }
  }
}
