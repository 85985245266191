<ng-container *ngFor="let toast of toasts; let i = index">
    <div class="toast toast-{{toast.type}} show" [style.top.px]="i*75" (click)="remove(i)">
        <!-- <div class="toast-header">
            <i class="bi bi-check-square-fill success" *ngIf="toast.type == 'success'"></i>
            <i class="bi bi-exclamation-square-fill warning" *ngIf="toast.type == 'warning'"></i>
            <i class="bi bi-x-square-fill error" *ngIf="toast.type == 'error'"></i>
            <i class="bi bi-info-square-fill information" *ngIf="toast.type == 'information'"></i>
            <strong class="ms-2">{{toast.title}}</strong>
            <button type="button" class="ms-auto mb-1 btn-close" aria-label="Close" (click)="remove(i)">
            </button>
        </div> -->
        <div class="toast-body">
            <button type="button" class="btn-close float-end" aria-label="Close" (click)="remove(i)">
            </button>
            <i class="bi bi-check-square-fill success" *ngIf="toast.type == 'success'"></i>
            <i class="bi bi-exclamation-square-fill warning" *ngIf="toast.type == 'warning'"></i>
            <i class="bi bi-x-square-fill error" *ngIf="toast.type == 'error'"></i>
            <i class="bi bi-info-square-fill information" *ngIf="toast.type == 'information'"></i>
            <span class="ms-2" style="word-break: break-all;" [innerHtml]="toast.body"></span>
        </div>
    </div>

</ng-container>
<div class="snackbar" [class.show]="snackbar.message">
    <div class="snackbar-body" (click)="closeSnackbar()">
        <button type="button" class="ms-2 btn-close btn-close-white float-end" aria-label="Close" (click)="closeSnackbar()">
        </button>
        <i class="fa fa-check-circle success" aria-hidden="true" *ngIf="snackbar.type == 'success'"></i>
        <i class="fa fa-times-circle error" aria-hidden="true" *ngIf="snackbar.type == 'error'"></i>
        <i class="fa fa-exclamation-circle warning" aria-hidden="true" *ngIf="snackbar.type == 'warning'"></i>
        <i class="fa fa-info-circle information" aria-hidden="true" *ngIf="snackbar.type == 'information'"></i>
        <span class="ms-2">{{snackbar.message}}</span>
    </div>
</div>