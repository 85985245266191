import { AfterViewInit, Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastNotifyService } from 'src/app/config/toast-config/toast-notify.service';
import { ModalService } from 'src/app/global/custom-modal/modal.service';
import { CartCalc } from '../../cart/shared/cart';
import { CartService } from '../../cart/shared/cart.service';
import { CheckoutService } from '../shared/checkout.service';

@Component({
  selector: 'app-checkout-summary',
  templateUrl: './checkout-summary.component.html',
  styleUrls: ['./checkout-summary.component.scss']
})
export class CheckoutSummaryComponent implements AfterViewInit {

  model: CartCalc = new CartCalc();
  isPayment: boolean = false;
  paymentRequest: any;

  constructor(
    private service: CartService,
    private orderService: CheckoutService,
    private modalService: ModalService,
    private toast: ToastNotifyService,
    private router: Router
  ) { }

  ngAfterViewInit(): void {
    if (this.router.url === '/checkout/complete') this.getOrderTotal();
    else this.getCartTotal();
  }

  getOrderTotal() {
    let orderid = sessionStorage.getItem('order') as string;
    if (!orderid) return;
    this.orderService.getOrderReviewSummary(orderid).subscribe(res => {
      if (res) this.model = res;
      else this.model = new CartCalc();
    });
  }

  getCartTotal() {
    this.service.cartTotal().subscribe(res => {
      if (res) this.model = res;
      else {
        this.toast.information('', 'Your cart is empty please buy an item to proceed checkout.');
        this.router.navigateByUrl('/')
      }


      const dropConfig = {
        "components": [
          "order-details",
          "card",
          "netbanking",
          "app",
          "upi"
        ],
        "orderToken": "cdjVrc9NMk1ZjbIkJwqK",
        "onSuccess": function (data: any) {
          //on payment flow complete
        },
        "onFailure": function (data: any) {
          //on failure during payment initiation
        },
        "style": {
          //to be replaced by the desired values
          "backgroundColor": "#ffffff",
          "color": "#11385b",
          "fontFamily": "Lato",
          "fontSize": "14px",
          "errorColor": "#ff0000",
          "theme": "light", //(or dark)
        }
      }

      // const cashfree = new Cashfree();
      // const paymentElement = document.getElementById("payment-form");
      // cashfree.initialiseDropin(paymentElement, dropConfig);

      // else this.model = new CartCalc();
    })
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
