import { Component, OnInit, OnDestroy } from '@angular/core';
import { JsonLDService, BreadCrumbListSchema, WebpageSchema } from 'src/app/config/app-config/schemaorg.service';
import { SeoService } from 'src/app/config/app-config/seo.service';

@Component({
  selector: 'app-refund-cancellation',
  templateUrl: './refund-cancellation.component.html',
  styleUrls: ['./refund-cancellation.component.scss']
})
export class RefundCancellationComponent implements OnInit, OnDestroy {

  constructor(
    private seoService: SeoService,
    private jsonService: JsonLDService,
  ) { }

  ngOnInit(): void {
    this.seoService.generateTags({
      title: 'Refund & Cancellation Policy - Paithani & Silk Sarees',
      description: `Refund & cancellation policy by paithani and silk sarees.`,
      robot: 'index, follow',
      slug: '/refundandcancellation'
    });
    this.jsonService.makeStructuredSchema(BreadCrumbListSchema(window.location.href, [{
      "routes": "",
      "name": "Home - Paithani & Silk Sarees",
    },
    {
      "routes": "/refundandcancellation",
      "name": "Refund & Cancellation - Paithani & Silk Sarees",
    }]));
    this.jsonService.makeStructuredSchema(WebpageSchema(window.location.href, 'Refund & Cancellation - Paithani & Silk Sarees'));
  }

  ngOnDestroy(): void {
    this.jsonService.removeStructuredData();
  }
}
