import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppSharedService } from './app-shared.service';

@Injectable()
export class AccessGuard  {

    constructor(private router: Router, private sharedService: AppSharedService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isLoggedIn = this.sharedService.isAuth as boolean;
        if (isLoggedIn === true) {
            return true;
        }
        this.router.navigate(['/session/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}