import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/config/http-config/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  constructor(private httpService: HttpService) { }

  getBrandMenus(): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/brand/getbrandmenus';
    return this.httpService.get(obj);
  }

  getNavbarCollections(): Observable<any> {
    const obj: any = {};
    obj.url = environment.appSettings.baseURI + '/collection/navbar';
    return this.httpService.get(obj);
  }

}
