<div class="row justify-content-center mt-5 mb-5">
    <div class="col-lg-8 col-md-8 ">
        <div id="contact" class="box">
            <h1>Contact</h1>
            <p class="lead">Are you curious about something? Do you have some kind of problem with our products?</p>
            <p>Please feel free to contact us, our customer service center is working for you 24/7.</p>
            <hr>
            <div class="row">
                <div class="col-md-4">
                    <h3><i class="fa fa-map-marker"></i>Address</h3>
                    <p>Pahad Lane, Near Post Office,<br>Tal-Yeola, Dist.-Nashik, <br><strong>MH, India
                            (423401)</strong></p>
                    <br>
                    <h3><i class="fa fa-envelope"></i> Electronic support</h3>
                    <p class="text-muted">Please feel free to write an email to us
                        system. <br><strong><a
                                href="mailto:paithaniandsilksaree.com">paithaniandsilksaree.com</a></strong></p>
                </div>
                <div class="col-md-8" [formGroup]="reactiveForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label for="firstname">Firstname</label>
                                <input id="firstname" type="text" class="form-control" [(ngModel)]="model.fname"
                                    formControlName="fname"
                                    [ngClass]="{ 'is-invalid': !f.fname.valid && f.fname.errors && f.fname.touched }" />
                                <div *ngIf="f.fname.errors && f.fname.touched" class="invalid-feedback">
                                    <p *ngIf="f.fname.errors.required">This field is required.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label for="lastname">Lastname</label>
                                <input id="lastname" type="text" class="form-control" [(ngModel)]="model.lname"
                                    formControlName="lname"
                                    [ngClass]="{ 'is-invalid': !f.lname.valid && f.lname.errors && f.lname.touched }" />
                                <div *ngIf="f.lname.errors && f.lname.touched" class="invalid-feedback">
                                    <p *ngIf="f.lname.errors.required">This field is required.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label for="email">Email</label>
                                <input id="email" type="email" class="form-control" [(ngModel)]="model.email"
                                    formControlName="email"
                                    [ngClass]="{ 'is-invalid': !f.email.valid && f.email.errors && f.email.touched }" />
                                <div *ngIf="f.email.errors && f.email.touched" class="invalid-feedback">
                                    <p *ngIf="f.email.errors.required">This field is required.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label for="Mobile">Mobile</label>
                                <input id="Mobile" type="tel" class="form-control" [(ngModel)]="model.mobile"
                                    formControlName="mobile"
                                    [ngClass]="{ 'is-invalid': !f.mobile.valid && f.mobile.errors && f.mobile.touched }" />
                                <div *ngIf="f.mobile.errors && f.mobile.touched" class="invalid-feedback">
                                    <p *ngIf="f.mobile.errors.required">Mobile no is required.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group mb-3">
                                <label for="subject">Subject</label>
                                <input id="subject" type="text" class="form-control" [(ngModel)]="model.subject"
                                    formControlName="subject"
                                    [ngClass]="{ 'is-invalid': !f.subject.valid && f.subject.errors && f.subject.touched }" />
                                <div *ngIf="f.subject.errors && f.subject.touched" class="invalid-feedback">
                                    <p *ngIf="f.subject.errors.required">This field is required.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group mb-3">
                                <label for="message">Message</label>
                                <textarea id="message" class="form-control" [(ngModel)]="model.message"
                                    formControlName="message"
                                    [ngClass]="{ 'is-invalid': !f.message.valid && f.message.errors && f.message.touched }">
                                </textarea>
                                <div *ngIf="f.message.errors && f.message.touched" class="invalid-feedback">
                                    <p *ngIf="f.message.errors.required">This field is required.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 text-center">
                            <button class="btn btn-primary" (click)="send()"><i class="fa fa-envelope-o"></i> Send
                                message</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>