import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { HomeService } from '../../home/shared/home.service';
import { BreadCrumbListSchema, JsonLDService, WebpageSchema } from 'src/app/config/app-config/schemaorg.service';
import { SeoService } from 'src/app/config/app-config/seo.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {
  reactiveForm: UntypedFormGroup;
  model: Model;

  constructor(
    private service: HomeService,
    private jsonService: JsonLDService,
    private seoService: SeoService,
  ) {
    this.model = new Model();
    this.reactiveForm = new UntypedFormGroup({
      'fname': new UntypedFormControl(null, [Validators.required]),
      'lname': new UntypedFormControl(null, [Validators.required]),
      'email': new UntypedFormControl(null, [Validators.required]),
      'mobile': new UntypedFormControl(null, [Validators.nullValidator]),
      'subject': new UntypedFormControl(null, [Validators.required]),
      'message': new UntypedFormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.seoService.generateTags({
      title: 'Contact Us - Paithani & Silk Sarees',
      description: `Are you curious about something? Do you have some kind of problem with our products? Please feel free to contact us, our customer service center is working for you 24/7.`,
      robot: 'index, follow',
      slug: '/contact'
    });
    this.jsonService.makeStructuredSchema(BreadCrumbListSchema(window.location.href, [{
      "routes": "",
      "name": "Home - Paithani & Silk Sarees",
    },
    {
      "routes": "/contact",
      "name": "Contact Us - Paithani & Silk Sarees",
    }]));
    this.jsonService.makeStructuredSchema(WebpageSchema(window.location.href, 'Contact Us - Paithani & Silk Sarees'));
  }

  ngOnDestroy(): void {
    this.jsonService.removeStructuredData();
  }

  get f(): any {
    return this.reactiveForm.controls;
  }

  send(): void {
    if (this.reactiveForm.invalid) {
      for (var i in this.reactiveForm.controls) {
        this.reactiveForm.controls[i].markAsTouched();
      }
      return;
    }

    this.service.sendMessage(this.model).subscribe(async (res: any) => {
      if (await this.alertBox() === true) {
        this.reactiveForm.reset();
        this.model = new Model();
      }
    })
  }

  async alertBox(): Promise<boolean> {
    let timerInterval: any;
    let swal = await Swal.fire(
      {
        icon: 'success',
        title: 'Message sent successfully!',
        html: '<h6>We appreciate that you have taken the time to write us. We will get back to you very soon. Please come back and see us often.</h6><br><p style="color:red;">Notification is auto close in <b>5</b>s.</p>',
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
          let b: any = Swal.getHtmlContainer()!.querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Math.round((Swal.getTimerLeft() as number / 1000))
          }, 1000)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }
    );
    return true;
  }
}

class Model {
  fname: string = '';
  lname: string = '';
  email: string = '';
  mobile: string = '';
  subject: string = '';
  message: string = '';
}
