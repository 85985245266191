<div class="row justify-content-center">
    <div class="col-lg-8 col-md-8 card p-5">
        <h1 class="text-center pb-2 text-uppercase"><strong>Shipping & Delivery</strong></h1>

        <p>Thank you for shopping at <strong><a href="/">Paithani & Silk Sarees</a></strong></p>

        <h4 class="fw-bold">How do I check the status of my order?</h4>
        <p>Please tap on "My Orders" section to check your order status.</p>
       
        <h4 class="fw-bold">Is Deliver To Outside India?</h4>
        <p>No. At this point, We delivers products only within India. Unfortunatly, we do not accept orders from outside india. We will soon start shipping goods out of India.</p>

        <h4 class="fw-bold">How are orders placed on Paithani & Silk Sarees delivered to me?</h4>
        <p>All orders placed on <b class="text-primary">Paithani & Silk Sarees</b> are dispatched through our sellers or else our own service. The courier partners such as DTDC, Shree Maruti Courier Services, Indian Post Etc.</p>

        <h4 class="fw-bold">How can I check if <span class="text-primary">Paithani & Silk Sarees</span> delivers to my PIN Code?</h4>
        <p>No. At this point, we do not having facility to check whether your order delivers to your pin code. For now if the courier partners not delivers to your pin code. We will deliver the courier throgh Indian Post.
            And if unfortunately, we can't deliver to your pin code. Then we will cancel your order and refund to your account within 7-10 business days.
        </p>

        <h4 class="fw-bold">How much shipping charges were apply?</h4>
        <p>You will receive a shipping fee on your cart page or on the checkout page. This shipping fee is based on courier facility.</p>

        <h4 class="fw-bold">How long will it take for the order to reach me?</h4>
        <p>Tipically it takes 4-7 buisness days to deliver order as per courier standard shipement service.</p>

    </div>
</div>