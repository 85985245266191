import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSharedService } from 'src/app/config/app-config/app-shared.service';
import { CartService } from 'src/app/pages/cart/shared/cart.service';
import { environment } from 'src/environments/environment';
import { accessory_menu_data, paithani_menu_data, saree_menu_data } from './navbar.data';
import { NavbarService } from './navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {
  model: Model;
  isTestMode = environment.appSettings.isTestMode;

  constructor(
    private service: NavbarService,
    public sharedService: AppSharedService,
    private router: Router,
    private route: ActivatedRoute,
    private cart: CartService,
    private elementRef: ElementRef
  ) {
    this.model = new Model();
  }

  ngAfterViewInit(): void {
    this.getNavbarCollections();
    this.getBrandMenus();
    if (this.sharedService.isAuth) {
      this.cart.invokeCartCount.next(true);
    }
  }

  async getCartCount(): Promise<void> {
    // let data = await this.cart.cartCount().toPromise();
    // if (data) {
    //   this.model.cart_cnt = data.cart;
    // }
  }

  async getNavbarCollections(): Promise<void> {
    let data: any = await this.service.getNavbarCollections().toPromise();
    if (Array.isArray(data)) {
      // Paithani Manu Navbar
      this.model.pathani_menu = this.getCollectionDataBySlug(data, 'paithani');

      // Saree Menu Navbar
      this.model.saree_menu = this.getCollectionDataBySlug(data, 'saree');

      // Accessories Menu Navbar
      this.model.accessories_menu = this.getCollectionDataBySlug(data, 'accessories');

      this.model.dress_menu = this.getCollectionDataBySlug(data, 'dress');
    }
  }

  onClick(event: any) {
    console.log(event);
  }

  getCollectionDataBySlug(data_arr: Array<any>, slug: string): Array<any> {
    let data = data_arr.find(x => x.slug === slug);
    if (!data) return [];
    const collection_slug = data.slug;
    let categories: Array<any> = data.category;
    categories.map(x => {
      const categorySlug = x.slug;
      x.slug = ['collections', collection_slug, categorySlug].join('/');
      let child: Array<any> = x.child;
      if (Array.isArray(child)) {
        child.map(cp => cp.slug = ['collections', collection_slug, categorySlug, cp.slug].join('/'));
      }
    });

    // categories.map(x => {
    //   const categorySlug = x.slug;
    //   x.slug = ['collections', this.encodeQueryData({ col: collection_slug, cat: categorySlug })].join('&');
    //   let child: Array<any> = x.child;
    //   if (Array.isArray(child)) {
    //     child.map(cp => cp.slug = ['collections', this.encodeQueryData({ col: collection_slug, cat: categorySlug, subc: cp.slug })].join('&'));
    //   }
    // });
    return categories;
  }

  encodeQueryData(data: any) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
  }

  async getBrandMenus() {
    let data: any = await this.service.getBrandMenus().toPromise();
    if (Array.isArray(data)) {
      this.model.brand_menu = data;
    }
  }

  setLeftPixels(items: Array<any>): string {
    if (items.length > 0) {
      if (!this.isHideMainSubMenu(items)) {
        return '-10%';
      } else if (items.length > 1) {
        return '-' + (120 / items.length) + '%';
      } else {
        return '-40%';
      }
    }
    return '0';
  }

  isHideMainSubMenu(items: Array<any>): boolean {
    let isOpen = false;
    items.map(x => {
      if (x.child) isOpen = true;
    });
    return isOpen;
  }

  goToCart(): void {
    this.router.navigate(['/cart']);
    // if (this.sharedService.isAuth)
    //   this.router.navigate(['/cart']);
    // else this.router.navigate(['/session/login']);
  }

}

class Model {
  pathani_menu = paithani_menu_data;
  saree_menu = saree_menu_data;
  dress_menu: any = [];
  accessories_menu = accessory_menu_data;
  brand_menu: any = [];
  paithani_box: boolean = false;
  saree_box: boolean = false;
  accessories_box: boolean = false;
  brand_box: boolean = false;
  cart_cnt: number = 0;
}