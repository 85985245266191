<header class="header">
    <div class="text-center" *ngIf="isTestMode"
        style="background-color: rgb(20, 172, 53); color: white; font-weight: 600;">
        Test Mode
    </div>
    <div id="top" hidden>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offer mb-3 mb-lg-0">
                    <a href="#" class="btn btn-success btn-sm">Offer of the day</a>
                    <a href="#" class="ms-1">Get flat 35% off on orders over ₹5000!</a>
                </div>
                <div class="col-lg-6 submenuheader">
                    <ul class="menu list-inline mb-0">
                        <li class="list-inline-item" *ngIf="!sharedService.isAuth"><a
                                [routerLink]="['session/login']">Login</a></li>
                        <li class="list-inline-item" *ngIf="!sharedService.isAuth"><a
                                [routerLink]="['session/register']">Register</a></li>
                        <li class="list-inline-item" *ngIf="sharedService.isAuth"><a routerLink="/account/profile">My
                                Account</a></li>
                        <li class="list-inline-item" *ngIf="sharedService.isAuth"><a routerLink="/account/myorders">My
                                Orders</a></li>
                        <!-- <li class="list-inline-item" *ngIf="sharedService.isAuth"><a routerLink="/account/profile">My
                                Wishlist</a></li> -->
                        <li class="list-inline-item" *ngIf="sharedService.isAuth"><a
                                routerLink="/session/login">Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <nav class="navbar navbar-expand-lg">
        <div class="container">
            <a routerLink="/" class="navbar-brand home">
                <img src="assets/images/P_LOGO.png" width="130" alt="logo" class="d-none d-md-inline-block">
                <img src="assets/images/P_LOGO.png" width="110" alt="logo" class="d-inline-block d-md-none">
                <span class="sr-only">Paithani & Silk Sarees</span>
            </a>
            <div class="navbar-buttons">
                <button type="button" data-bs-toggle="collapse" data-bs-target="#navigation"
                    class="btn btn-outline-secondary navbar-toggler"><span class="sr-only">Toggle navigation</span><i
                        class="fa fa-align-justify"></i></button>
                <!-- <button type="button" data-bs-toggle="collapse" data-bs-target="#search"
                    class="btn btn-outline-secondary navbar-toggler"><span class="sr-only">Toggle search</span><i
                        class="fa fa-search"></i></button> -->
                <button (click)="goToCart()" class="btn btn-outline-secondary navbar-toggler">
                    <i class="fa fa-shopping-cart"></i>
                    <span class="position-absolute badge rounded-pill bg-danger">
                        {{sharedService.cartCount}}
                    </span>
                </button>
            </div>
            <div id="navigation" class="collapse navbar-collapse">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item"><a routerLink="/" class="nav-link collapse-nav-link">Home</a></li>
                    <li class="nav-item dropdown menu-large" *ngIf="model.pathani_menu.length > 0">
                        <a routerLink="/collections/paithani" data-bs-toggle="dropdown" data-hover="dropdown"
                            data-delay="200" class="dropdown-toggle nav-link">Paithani<b class="caret"></b>
                        </a>
                        <ng-container *ngIf="model.pathani_menu.length > 0" [ngTemplateOutlet]="subMenuList"
                            [ngTemplateOutletContext]="{items: model.pathani_menu}">
                        </ng-container>
                    </li>
                    <li class="nav-item dropdown menu-large" *ngIf="model.saree_menu.length > 0">
                        <a routerLink="/collections/sarees" data-bs-toggle="dropdown" data-hover="dropdown"
                            data-delay="200" class="dropdown-toggle nav-link">Sarees<b class="caret"></b>
                        </a>
                        <ng-container [ngTemplateOutlet]="subMenuList"
                            [ngTemplateOutletContext]="{items: model.saree_menu}">
                        </ng-container>
                    </li>
                    <li class="nav-item dropdown menu-large" *ngIf="model.dress_menu.length > 0">
                        <a routerLink="/collections/dress" data-bs-toggle="dropdown" data-hover="dropdown"
                            data-delay="200" class="dropdown-toggle nav-link">Dress<b class="caret"></b>
                        </a>
                        <ng-container [ngTemplateOutlet]="subMenuList"
                            [ngTemplateOutletContext]="{items: model.dress_menu}">
                        </ng-container>
                    </li>
                    <li class="nav-item dropdown menu-large" *ngIf="model.accessories_menu.length > 0">
                        <a routerLink="/collections/accessories" data-bs-toggle="dropdown" data-hover="dropdown"
                            data-delay="200" class="dropdown-toggle nav-link">Accessories<b class="caret"></b>
                        </a>
                        <ng-container *ngIf="model.accessories_menu.length > 0" [ngTemplateOutlet]="subMenuList"
                            [ngTemplateOutletContext]="{items: model.accessories_menu}">
                        </ng-container>
                    </li>
                    <li class="nav-item dropdown menu-large" *ngIf="model.brand_menu.length > 0">
                        <a routerLink="/collections/brandandshops" data-bs-toggle="dropdown" data-hover="dropdown"
                            data-delay="200" class="dropdown-toggle nav-link">Brands & Shops<b class="caret"></b>
                        </a>
                        <ng-container *ngIf="model.brand_menu.length > 0" [ngTemplateOutlet]="subMenuList"
                            [ngTemplateOutletContext]="{items: model.brand_menu }">
                        </ng-container>
                    </li>
                    <li class="nav-item dropdown menu-large" *ngIf="sharedService.isAuth">
                        <a type="button" data-bs-toggle="dropdown" data-hover="dropdown" data-delay="200"
                            class="dropdown-toggle nav-link">Account<b class="caret"></b>
                        </a>
                        <ul class="dropdown-menu megamenu">
                            <li>
                                <div class="row">
                                    <div class="col-md-6 col-lg-3">
                                        <a routerLink="/account/profile" class="collapse-nav-link">
                                            <h5>My Account</h5>
                                        </a>
                                    </div>
                                    <div class="col-md-6 col-lg-3">
                                        <a routerLink="/account/myorders" class="collapse-nav-link">
                                            <h5>My Order</h5>
                                        </a>
                                    </div>
                                    <div class="col-md-6 col-lg-3">
                                        <a routerLink="/session/login" class="collapse-nav-link">
                                            <h5>Logout</h5>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item" *ngIf="!sharedService.isAuth">
                        <a routerLink="/session/login" class="nav-link collapse-nav-link">Login</a>
                    </li>
                </ul>
                <div class="navbar-buttons d-flex justify-content-end ms-3">
                    <div id="search-not-mobile" class="navbar-collapse collapse"></div>
                    <!-- <a data-bs-toggle="collapse"
                        href="#search" class="btn navbar-btn btn-primary d-none d-lg-inline-block"><span
                            class="sr-only">Toggle search</span><i class="fa fa-search"></i></a> -->
                    <div id="basket-overview" class="navbar-collapse collapse d-none d-lg-block">
                        <button class="btn btn-primary navbar-btn" (click)="goToCart()"><i
                                class="fa fa-shopping-cart"></i><span>{{sharedService.cartCount}} items in
                                cart</span></button>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <div id="search" class="collapse">
        <div class="container">
            <form role="search" class="ml-auto">
                <div class="input-group">
                    <input type="text" placeholder="Search" class="form-control">
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary"><i class="fa fa-search"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</header>


<ng-template #subMenuList let-items="items">
    <ul class="dropdown-menu megamenu">
        <li>
            <div class="row">
                <div class="col-md-6 col-lg-3" *ngFor="let item of items">
                    <a [routerLink]="item.slug" class="collapse-nav-link" data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse">
                        <h5>{{item.name}}</h5>
                    </a>
                    <ul class="list-unstyled mb-3" *ngIf="item.child && item.child.length > 0">
                        <li class="nav-item" *ngFor="let subitem of item.child">
                            <a [routerLink]="subitem.slug" class="nav-link" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse">{{subitem.name}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    </ul>
</ng-template>