import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RoutesRecognized,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppSharedService } from './app-shared.service';

@Injectable()
export class RouteChangeService {
  data: any;
  subs: Array<Subscription> = [];
  constructor(
    private router: Router,
    private sharedService: AppSharedService,
    private titleService: Title,
    public route: ActivatedRoute,
  ) {

    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
      }

      if (e instanceof NavigationEnd) {
      }

      if (e instanceof NavigationCancel) {
      }

      if (e instanceof NavigationError) {
      }

      if (e instanceof RoutesRecognized) {
        this.subs[0] = this.router.events
          .pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.route.snapshot),
            map(resRoute => {
              while (resRoute.firstChild) {
                resRoute = resRoute.firstChild;
              }
              return resRoute;
            })
          )
          .subscribe((subRoute: ActivatedRouteSnapshot) => {
            this.data = subRoute.data;
            if (this.data['active']) {
              this.sharedService.activateTemplate = this.data['active'];
            }
            if (this.data['title'] !== undefined) {
              this.titleService.setTitle(this.data['title']);
            }
          });
      }
    });
  }
}
