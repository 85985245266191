import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class JsonLDService {
    static scriptType = 'application/ld+json';

    static productSchema = (name: string, desc: string, logo: string, images: string[], cateogry: string) => {
        return {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": name,
            "logo": logo,
            "image": images,
            "description": desc,
            "category": cateogry,
            // "sku": "0446310786",
            // "mpn": "925872",
            // "brand": {
            //     "@type": "Brand",
            //     "name": "ACME"
            // },
            "review": {
                "@type": "Review",
                "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "4",
                    "bestRating": "5"
                },
                // "author": {
                //     "@type": "Person",
                //     "name": "Fred Benson"
                // }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.4",
                "reviewCount": "89"
            },
            // "offers": {
            //     "@type": "Offer",
            //     "url": "https://example.com/anvil",
            //     "priceCurrency": "USD",
            //     "price": "119.99",
            //     "priceValidUntil": "2020-11-20",
            //     "itemCondition": "https://schema.org/UsedCondition",
            //     "availability": "https://schema.org/InStock"
            // }
        }
    }

    structuredSchema: any[] = [];

    constructor(@Inject(DOCUMENT) private _document: Document) {
        this.structuredSchema.push(OrganizationSchema());
        this.structuredSchema.push(DefaultWebsiteSchema());
    }

    makeStructuredSchema(schema: any): void {
        this.removeJsonLDClass();
        const x = this.structuredSchema.find(x => JSON.stringify(x) == JSON.stringify(schema));
        if (!x) {
            this.structuredSchema.push(schema);
        }
        this.insertSchema({
            '@context': 'https://schema.org',
            '@graph': this.structuredSchema
        });
    }

    removeJsonLDClass(): void {
        const els: any[] = [];
        ['structured-data', 'structured-data-org', 'structured-breadcrumb'].forEach(c => {
            els.push(...Array.from(this._document.head.getElementsByClassName(c)));
        });
        els.forEach(el => this._document.head.removeChild(el));
    }

    removeStructuredData(): void {
        const els: any[] = [];
        ['structured-data', 'structured-data-org', 'structured-breadcrumb'].forEach(c => {
            els.push(...Array.from(this._document.head.getElementsByClassName(c)));
        });
        els.forEach(el => this._document.head.removeChild(el));
        this.structuredSchema = [];
        this.structuredSchema.push(OrganizationSchema());
        this.structuredSchema.push(DefaultWebsiteSchema());
    }

    insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
        let script: any;
        let shouldAppend = false;
        if (this._document.head.getElementsByClassName(className).length) {
            script = this._document.head.getElementsByClassName(className)[0];
        } else {
            script = this._document.createElement('script');
            shouldAppend = true;
        }
        script.setAttribute('class', className);
        script.type = JsonLDService.scriptType;
        script.text = JSON.stringify(schema);
        if (shouldAppend) {
            this._document.head.appendChild(script);
        }
    }
}

export interface IProductJsonSchema {
    name: string;
    desc: string;
    logo: string;
    images: string[];
    cateogry: string;
    url: string;
    price: string;
    sku: string;
    date: Date;
    brand: string;
    material: string;
    inStock: boolean;
}

export function ProductJsonSchema(data: IProductJsonSchema) {
    const x: any = {
        // "@context": "https://schema.org/",
        "@type": "Product",
        "@id": data.url + "#product",
        "name": data.name,
        "logo": data.logo,
        "image": data.images,
        "description": data.desc,
        "category": data.cateogry,
        "sku": data.sku,
        "review": {
            "@type": "Review",
            "reviewRating": {
                "@type": "Rating",
                "ratingValue": "4",
                "bestRating": "5"
            },
            "author": {
                "@type": "Person",
                "name": "Paithani & Silk Saree"
            }
        },

        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.4",
            "reviewCount": "89"
        },
        "offers": {
            "@type": "Offer",
            "url": data.url,
            "priceCurrency": "INR",
            "price": data.price,
            "availability": `https://schema.org/${data.inStock ? 'InStock' : 'OutOfStock'}`,
            "itemCondition": "http://schema.org/NewCondition",
            "image": data.logo,
            "name": data.name,
            "sku": data.sku,
            "description": data.desc,
            "priceValidUntil": data.date,
            "hasMerchantReturnPolicy": {
                "@type": "MerchantReturnPolicy",
                "applicableCountry": "IN",
                "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
                "merchantReturnDays": 7,
                "returnMethod": "https://schema.org/ReturnByMail",
                "returnFees": "https://schema.org/FreeReturn"
            },
            "shippingDetails": [
                {
                    "@type": "OfferShippingDetails",
                    "shippingRate": {
                        "@type": "MonetaryAmount",
                        "currency": "INR",
                        "value": "0.00",
                        "name": "Free Shipping"
                    },
                    "shippingDestination": {
                        "@type": "DefinedRegion",
                        "addressCountry": "IN"
                    },
                    "shippingLabel": "Free Shipping within India",
                    "deliveryTime": {
                        "@type": "ShippingDeliveryTime",
                        "handlingTime": {
                            "@type": "QuantitativeValue",
                            "minValue": 0,
                            "maxValue": 2,
                            "unitCode": "DAY"
                        },
                        "transitTime": {
                            "@type": "QuantitativeValue",
                            "minValue": 2,
                            "maxValue": 7,
                            "unitCode": "DAY"
                        }
                    }
                },
                {
                    "@type": "OfferShippingDetails",
                    // "shippingDestination": {
                    //     "@type": "DefinedRegion",
                    //     "name": "Other Countries"
                    // },
                    "shippingRate": {
                        "@type": "MonetaryAmount",
                        "name": "Shipping Fee",
                        "description": "Varies by country standards",
                        "currency": "USD",
                        "maxValue": 50
                    },
                    "shippingLabel": "Shipping fees apply to other countries",
                    "deliveryTime": {
                        "@type": "ShippingDeliveryTime",
                        "handlingTime": {
                            "@type": "QuantitativeValue",
                            "minValue": 0,
                            "maxValue": 2,
                            "unitCode": "DAY"
                        },
                        "transitTime": {
                            "@type": "QuantitativeValue",
                            "minValue": 5,
                            "maxValue": 18,
                            "unitCode": "DAY"
                        }
                    }
                }
            ]

        }
    }

    if (data.brand) {
        x['brand'] = {
            '@type': "Brand",
            name: data.brand
        };
    };

    if (data.material) {
        x['material'] = data.material;
    }

    return x;
}


export function WebsiteSchema(url: string, name: string) {
    return {
        // '@context': 'https://schema.org',
        '@type': 'WebSite',
        '@id': url + '#website',
        url: url,
        name: name,
        "inLanguage": "en-US",
        "sameAs": [
            "https://facebook.com/paithaniandsilksaree",
            "https://instagram.com/paithaniandsilksarees",
            "https://twitter.com/paithani_store"
        ],
        "publisher": {
            "@id": "https://paithaniandsilksaree.com/#organization"
        },
    };
};

export function DefaultWebsiteSchema() {
    return {
        // '@context': 'https://schema.org',
        '@type': 'WebSite',
        '@id': 'https://paithaniandsilksaree.com/#website',
        url: 'https://paithaniandsilksaree.com',
        name: 'Paithani & Silk Sarees',
        "inLanguage": "en-US",
        "sameAs": [
            "https://facebook.com/paithaniandsilksaree",
            "https://instagram.com/paithaniandsilksarees",
            "https://twitter.com/paithani_store"
        ],
        "publisher": {
            "@id": "https://paithaniandsilksaree.com/#organization"
        },
    };
};

export function WebpageSchema(url: string, name: string, isProduct?: boolean) {
    const x: any = {
        "@type": "WebPage",
        "@id": url + '#webpage',
        "url": url,
        "name": name,
        "isPartOf": {
            "@id": 'https://paithaniandsilksaree.com/#website',
        },
        "primaryImageOfPage": {
            "@id": "https://paithaniandsilksaree.com/assets/images/P_LOGO.webp"
        },
        "inLanguage": "en-US",
        "breadcrumb": {
            "@id": url + "#breadcrumb"
        }
    };
    if (isProduct) {
        x['mainEntity'] = {
            '@id': url + '#product'
        }
    }
    return x;
}

export function OrganizationSchema() {
    return {
        // '@context': 'https://schema.org',
        '@type': 'Organization',
        '@id': "https://paithaniandsilksaree.com/#organization",
        name: "Paithani & silk saree",
        url: 'https://paithaniandsilksaree.com',
        email: "paithaniandsilksaree@gmail.com",
        description: 'Shop for online Paithani Sarees & Silk Sarees. Discover best Paithani Sarees & Accessories like Paithani Purse etc',
        "sameAs": [
            "https://facebook.com/paithaniandsilksaree",
            "https://instagram.com/paithaniandsilksarees",
            "https://twitter.com/paithani_store"
        ],
        "logo": LogoSchema(),
        // "mainEntityOfPage": {
        //     "@type": "WebPage",
        //     "url": "https://paithaniandsilksaree.com"
        // }
    };
}

export function BreadCrumbListSchema(url: string, items: { routes: string, name: string }[]) {
    const x: any = {
        // '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        '@id': url + "#breadcrumb",
        name: "Paithani And Silk Saree Breadcrumb"
    };
    x['itemListElement'] = [];
    items.map((y, i) => {
        x['itemListElement'].push(BreadCrumbItemListElement(y.routes, y.name, (i + 1)))
    })
    return x;
}

export function BreadCrumbItemListElement(routes: string, name: string, position: number) {
    return {
        // '@context': 'https://schema.org',
        '@type': 'ListItem',
        position: position,
        "item": {
            "@type": "Thing",
            '@id': environment.appSettings.hostUrl + routes,
            'name': name,
        }
    };
}

function LogoSchema() {
    return {
        "@type": "ImageObject",
        "@id": "https://paithaniandsilksaree.com/#logo",
        name: "Paithani & Silk Saree Logo",
        "url": "https://paithaniandsilksaree.com/assets/images/P_LOGO.webp",
        "contentUrl": "https://paithaniandsilksaree.com/assets/images/P_LOGO.webp",
        "caption": "Paithani & Silk Saree",
        "inLanguage": "en-US"
    }
}