<div class="row justify-content-center">
    <div class="col-lg-8 col-md-8 card p-5">
        <h2 class="text-center pb-2"><strong>FAQ</strong></h2>

       <div class="text-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="177.14 315.445 244 215">
            <path fill="#FDFDFD" d="M298.542 525.774c34.666 0 69.334.013 104-.008 11.252-.007 16.643-9.397 11.023-19.083-5.164-8.906-10.307-17.827-15.447-26.746-29.525-51.208-59.023-102.432-88.592-153.613-5.865-10.153-16.016-7.986-20.42-.045-5.413 9.75-11.118 19.34-16.69 29.01-22.87 39.68-45.73 79.36-68.607 119.03-6.74 11.69-13.58 23.31-20.25 35.04-2.78 4.89-2.12 10.57 1.62 13.13 3.04 2.07 6.31 3.39 10.35 3.37 34.33-.18 68.67-.11 103-.1z"/>
            <path fill="#F2BD01" d="M298.542 525.772c-34.333-.003-68.667-.08-103 .1-4.04.022-7.313-1.295-10.352-3.373-3.738-2.56-4.406-8.24-1.625-13.13 6.67-11.73 13.505-23.36 20.244-35.05 22.87-39.68 45.73-79.36 68.6-119.04 5.57-9.67 11.27-19.26 16.69-29.02 4.4-7.94 14.55-10.11 20.42.04 29.57 51.18 59.06 102.4 88.59 153.61 5.14 8.92 10.28 17.84 15.44 26.74 5.62 9.69.23 19.08-11.02 19.08-34.67.02-69.34.01-104 0zm.467-4.14v.017c11.16 0 22.33-.01 33.49 0 22.99 0 45.99 0 68.99.01 3.83 0 7.23-.78 9.48-4.32 2.37-3.74-.82-6.11-2.04-8.97-.26-.6-.68-1.12-1-1.69-34.11-59.19-68.21-118.38-102.33-177.56-4.16-7.21-8.97-7.13-13.18.16-9.12 15.79-18.22 31.59-27.33 47.39-14.53 25.21-29.06 50.43-43.6 75.63-11.05 19.16-22.13 38.3-33.17 57.47-3.48 6.04-1.21 10.69 5.68 11.74 1.3.2 2.66.11 3.99.11h100.99z"/>
            <path fill="#110D02" d="M299.01 521.633c-33.664 0-67.327 0-100.99-.004-1.33 0-2.684.08-3.99-.12-6.893-1.05-9.165-5.7-5.687-11.74 11.03-19.17 22.113-38.31 33.163-57.47 14.537-25.21 29.062-50.43 43.593-75.64 9.1-15.8 18.2-31.6 27.32-47.39 4.21-7.29 9.02-7.37 13.18-.17 34.12 59.18 68.22 118.37 102.33 177.56.32.56.74 1.09 1 1.68 1.23 2.85 4.4 5.23 2.04 8.96-2.25 3.54-5.65 4.32-9.48 4.32-23-.01-46-.01-68.99-.02h-33.49c-.01-.01-.01-.01-.01-.02zm-.112-4.742c32.826 0 65.652.01 98.48 0 1.497 0 3.01.08 4.495-.07 4.945-.48 5.443-1.46 3.004-5.7-7.775-13.52-15.47-27.09-23.36-40.54-11.77-20.06-23.474-40.18-35.01-60.37-14.507-25.39-29.228-50.67-43.624-76.13-3.768-6.66-3.83-6.63-7.535-.2-15.207 26.43-30.4 52.86-45.617 79.28-18 31.27-35.85 62.62-54.15 93.72-6.58 11.2-3.67 9.97 5.84 10 32.5.12 64.99.05 97.48.03z"/>
            <path fill="#F2BD01" d="M298.898 516.898c-32.494.006-64.987.077-97.48-.038-9.517-.034-12.426 1.195-5.837-10 18.3-31.095 36.15-62.454 54.15-93.717 15.22-26.418 30.42-52.85 45.62-79.272 3.71-6.43 3.77-6.46 7.54.2 14.4 25.46 29.12 50.74 43.63 76.13 11.54 20.2 23.24 40.31 35.01 60.38 7.9 13.46 15.59 27.02 23.36 40.54 2.44 4.25 1.95 5.22-3 5.71-1.48.15-2.99.07-4.49.08-32.82.01-65.65 0-98.48.01zm13.787-39.888c-6.633 4.356-10.652 10.556-15.45 16.01-.823.936-2.1 2.005-1.255 3.473.816 1.42 2.393.832 3.646.832 18.975 0 37.95-.024 56.926-.104 1.217 0 2.885.74 3.598-.75.666-1.39-.693-2.48-1.436-3.48-6.75-9.07-13.5-18.15-20.414-27.1-4.266-5.52-10.52-5.58-15.23-.32-2.106 2.36-4.144 4.79-5.99 7.35-1.454 2.01-2.442 1.92-4.228.26-4.383-4.07-8.893-8.04-13.592-11.75-2.527-1.99-3.25-4.14-3.03-7.27.604-8.63.94-17.28 1.258-25.93.208-5.66-1.98-7.94-7.576-8.17-2.827-.11-5.736.29-8.472-.24-9.174-1.78-15.005 1.72-18.55 10.27-1.163 2.81-2.934 5.44-2.045 8.42.547 1.84 1 6.54 4.76 2.13 1.18-1.38 1.92-.46 2.892.19.963.65 1.84 2.53 3.23.94 1.317-1.5.068-2.73-1.028-3.81-.94-.92-1.96-1.66-1.73-3.32.82-5.77 4.87-8.54 11.02-7.06-3.03 5.98-6.04 11.87-8.99 17.79-.53 1.07-1.99 2.37-.21 3.42 3.29 1.94 6.57 4.75 10.1 5.19 5.34.67 7.09 3.487 8.39 7.967.54 1.87 2.44 5.55 4.65 3.57 2.75-2.48 3.59-.52 5.05.618 4.44 3.47 8.81 7.03 13.68 10.926zm-40.018-16.905c4.953 6.77 10.548 12.325 9.658 21.13-.313 3.093.52 6.287.632 9.442.086 2.397 1.125 4.108 3.042 5.392 1.12.75 2.41 1.51 3.68 1 1.64-.67.85-2.33.82-3.56-.15-5.49-.92-11.02-.49-16.45.84-10.67-8.91-22.53-18.95-25.97-2.05-.7-3.02-.23-3.95 1.53-2.17 4.11-4.47 8.16-6.68 12.26-3.77 7-7.58 13.98-11.24 21.03-1.41 2.72-.09 9.42 1.87 9.55 3.14.19 2.91-3.4 4.5-5.06.56-.58.85-1.42 1.26-2.14 5.18-9.22 10.35-18.44 15.82-28.18zm31.086-51.816c-3.988.1-7.55 3.72-7.588 7.72-.042 4.18 3.784 7.9 7.99 7.78 4.23-.12 7.843-4.03 7.58-8.2-.255-4.02-3.96-7.42-7.982-7.32z"/>
            <path fill="#110D02" d="M312.685 477.01c-4.867-3.896-9.244-7.454-13.688-10.928-1.458-1.138-2.293-3.095-5.048-.617-2.21 1.98-4.11-1.696-4.65-3.565-1.31-4.48-3.05-7.293-8.4-7.966-3.54-.444-6.82-3.258-10.11-5.194-1.78-1.05-.32-2.35.21-3.415 2.95-5.92 5.96-11.81 8.99-17.785-6.16-1.478-10.21 1.298-11.03 7.065-.24 1.66.79 2.405 1.72 3.323 1.09 1.078 2.34 2.313 1.03 3.813-1.4 1.59-2.27-.29-3.24-.93-.97-.65-1.71-1.57-2.89-.19-3.76 4.41-4.22-.29-4.76-2.12-.89-2.98.88-5.61 2.04-8.42 3.54-8.54 9.37-12.04 18.55-10.26 2.73.54 5.64.14 8.47.25 5.59.23 7.78 2.51 7.57 8.17-.32 8.65-.66 17.3-1.26 25.93-.22 3.13.5 5.28 3.03 7.27 4.7 3.71 9.21 7.68 13.59 11.75 1.78 1.66 2.77 1.76 4.23-.25 1.85-2.56 3.88-4.99 5.99-7.35 4.71-5.26 10.96-5.2 15.23.32 6.91 8.95 13.66 18.03 20.41 27.11.74 1 2.1 2.09 1.43 3.48-.71 1.49-2.38.75-3.6.76-18.97.08-37.95.1-56.92.1-1.256 0-2.83.59-3.648-.83-.843-1.47.434-2.53 1.256-3.47 4.795-5.45 8.816-11.65 15.45-16.01zm-23.476-24.938v-9.632l-4.74 8.095 4.74 1.537z"/>
            <path fill="#110D02" d="M272.667 460.105l-15.823 28.18c-.407.725-.703 1.56-1.26 2.142-1.586 1.655-1.36 5.25-4.498 5.053-1.958-.123-3.286-6.822-1.874-9.55 3.655-7.058 7.463-14.036 11.234-21.034 2.208-4.096 4.508-8.143 6.674-12.26.925-1.76 1.9-2.234 3.95-1.534 10.036 3.432 19.79 15.298 18.947 25.964-.43 5.438.344 10.964.492 16.452.03 1.228.82 2.887-.83 3.553-1.28.52-2.57-.25-3.69-1-1.92-1.28-2.96-2.99-3.04-5.39-.12-3.15-.95-6.35-.64-9.44.89-8.8-4.71-14.36-9.66-21.13zm31.086-51.815c4.02-.105 7.73 3.292 7.98 7.312.262 4.174-3.348 8.076-7.582 8.197-4.2.11-8.03-3.61-7.98-7.79.04-4 3.6-7.62 7.59-7.73z"/>
            <path fill="#F2BD01" d="M289.21 452.072l-4.74-1.537 4.74-8.095v9.632z"/>
        </svg>
        <h5>Under construction</h5>
       </div>

    </div>
</div>