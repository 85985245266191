import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable()
export class SeoService {
    constructor(
        private meta: Meta,
        private title: Title,
        @Inject(DOCUMENT) private doc: Document
    ) { }

    generateTags(config: any) {
        config = {
            title: 'Paithani & Silk Sarees',
            description: 'Shop for online Paithani Sarees & Silk Sarees. Discover best Paithani Sarees & Accessories like Paithani Purse etc',
            image: 'https://paithaniandsilksaree.com/assets/images/P_LOGO.png',
            slug: '',
            robot: 'index, follow',
            author: 'Paithani & Silk Sarees',
            keywords: 'paithani, paithani silk sarees, best online paithani,  shivshahi paithani, paithani sarees colours, paithani saree price, marathi paithani saree, paithani dress, paithani sarees online, paithani saree for wedding, marathi wedding paithani saree, semi paithani saree, engagement marathi paithani saree, kasturi paithani, green paithani saree, original paithani saree, kapsepaithani, original paithani saree price, black paithani saree, pure paithani saree,',
            ...config
        };
        // this.title.setTitle(this.shared.makeTitleForSeo(config.title, config.noTitleChange));
        this.meta.updateTag({ name: 'description', content: config.description });
        this.meta.updateTag({ name: 'keywords', content: config.keywords });
        this.meta.updateTag({ name: 'robots', content: config.robot });






        // Twitter Tags
        this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.meta.updateTag({ name: 'twitter:site', content: '@paithani_store' });
        this.meta.updateTag({ name: 'twitter:title', content: config.title });
        this.meta.updateTag({ name: 'twitter:description', content: config.description });
        this.meta.updateTag({ name: 'twitter:image', content: config.image });
        this.meta.updateTag({ name: 'twitter:url', content: environment.appSettings.hostUrl + config.slug });
        this.meta.updateTag({ name: 'twitter:author', content: config.author });

        // Open Graph Tags
        this.meta.updateTag({ property: 'og:author', content: config.author });
        this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:site_name', content: 'Paithani & Silk Sarees' });
        this.meta.updateTag({ property: 'og:title', content: config.title });
        this.meta.updateTag({ property: 'og:description', content: config.description });
        this.meta.updateTag({ property: 'og:image', content: config.image });
        this.meta.updateTag({ property: 'og:url', content: environment.appSettings.hostUrl + config.slug });

        // this.createAmphtmlLink(config.slug);
        this.createCanonicalLink(config.slug);

    }

    createCanonicalLink(slug: string) {
        this.removeHeadEle('CANONICALLINK');
        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('id', 'CANONICALLINK');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', environment.appSettings.hostUrl + slug);
        this.doc.head.appendChild(link);
    }

    removeHeadEle(name: string): void {
        var head = document.getElementById(name);
        if (head) {
            head.remove();
        }
    }
}
