import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadCrumbListSchema, JsonLDService, WebpageSchema } from 'src/app/config/app-config/schemaorg.service';
import { SeoService } from 'src/app/config/app-config/seo.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {

  constructor(
    private seoService: SeoService,
    private jsonService: JsonLDService,
  ) { }

  ngOnInit(): void {
    this.seoService.generateTags({
      title: 'FAQ - Paithani & Silk Sarees',
      description: `Comming Soon`,
      robot: 'noindex, nofollow',
      slug: '/faq'
    });
    this.jsonService.makeStructuredSchema(BreadCrumbListSchema(window.location.href, [{
      "routes": "",
      "name": "Home - Paithani & Silk Sarees",
    },
    {
      "routes": "/faq",
      "name": "FAQ - Paithani & Silk Sarees",
    }]));
    this.jsonService.makeStructuredSchema(WebpageSchema(window.location.href, 'FAQ - Paithani & Silk Sarees'));
  }

  ngOnDestroy(): void {
    this.jsonService.removeStructuredData();
  }

}
