<div class="row justify-content-center">
    <div class="col-lg-8 col-md-8 card p-5">
        <h1 class="text-center pb-2 text-uppercase"><strong>Refund & Cancellation</strong></h1>

        <p>Thank you for shopping at <strong>https://paithaniandsilksaree.com</strong></p>
        <p>If, for any reason, You are not completely satisfied with a purchase, we invite You to review our Policy.
        </p>
        <p>The following terms are applicable for any products that you purchased with us.</p><br>

        <h3 class="text-uppercase">Return Process</h3>
        <ul>
            <li>The person does not recieved the product from courier services or the person has not available to accept
                the courier. Then courier service will return this item to source address.</li>
            <li>If courier services returning item to source address. This return process will be accepted by us and
                send for refund process after receiving this item to source address.</li>
            <li>Items that are bought from the Physical Stores can’t be returned.</li>
            <li>If you are recieving defected or damage item you can apply for return process vai creating ticket for
                return item issue with video proof. We are accepting this return issue only if the item has been covered in full
                disposing video after receiving the item. Our team will inspect this issue and after that the return will accept.</li>
        </ul>

        <h3 class="text-uppercase">Cancellation Process</h3>
        <ul>
            <li>User can cancel the item before the item has not been send to courier facility.</li>
            <li>User can also have option to reject the cancellation process before refund has not proceed.</li>
            <li>Cancellation accepted after review the reason to cancel the item.</li>
        </ul>

        <h3 class="text-uppercase">Refund Process</h3>
        <ul>
            <li>After we recieve the item, our team will inspect it and process your refund item amount in 7 to 10
                buisness days.</li>
            <li>The Amount will be refunded to the same payment method you have used during the purchase</li>
            <li>If the payment was been failed refunded to same payment method. We will allow you to use different bank
                information to send the refund amount.</li>
            <li>Other bank information need extra information : Full name and IBAN / Bank number, IFSC code, Branch name
                if need extra information we will inform you on mail.</li>
            <li>Item that were status as a Delivered is not allowed to be Returned or Refunded.</li>
        </ul>

        <h4><b>You will be updated for their Return Status through their EMAIL, provided that contact information is
                recorded to us.</b></h4>

    </div>
</div>