import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSharedService } from './app-shared.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private sharedService: AppSharedService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq: any = null;
        const isAuth = this.sharedService.isAuth as boolean;
        const authHeader = 'bearer ' + this.sharedService.token;
        if (isAuth && authHeader) {
            authReq = req.clone({ headers: req.headers.set('Authorization', authHeader) });
        } else {
            authReq = req.clone();
        }
        return next.handle(authReq);
    }
}
