import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-app-layout',
  templateUrl: './session-app-layout.component.html',
  styleUrls: ['./session-app-layout.component.scss']
})
export class SessionAppLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
